@font-face {
    font-family: SfProd;
    src: url('./SFPRODISPLAYREGULAR.woff2') format('woff2'),
    url('./SFPRODISPLAYREGULAR.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: SfProd;
    src: url('./SFPRODISPLAYREGULAR.woff2') format('woff2'),
    url('./SFPRODISPLAYREGULAR.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: SfProd;
    src: url('./SFPRODISPLAYMEDIUM.woff2') format('woff2'),
    url('./SFPRODISPLAYMEDIUM.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: SfProd;
    src: url('./SFPRODISPLAYBOLD.woff2') format('woff2'),
    url('./SFPRODISPLAYBOLD.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
