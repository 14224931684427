@font-face {
    font-family: Roboto Condensed;
    src: url('./RobotoCondensed-Light.woff2') format('woff2'),
    url('./RobotoCondensed-Light.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Roboto Condensed;
    src: url('./RobotoCondensed-Regular.woff2') format('woff2'),
    url('./RobotoCondensed-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Roboto Condensed;
    src: url('./RobotoCondensed-Bold.woff2') format('woff2'),
    url('./RobotoCondensed-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: Roboto;
    src: url('./Roboto-Regular.woff2') format('woff2'),
    url('./Roboto-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url('./Roboto-Medium.woff2') format('woff2'),
    url('./Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url('./Roboto-Bold.woff2') format('woff2'),
    url('./Roboto-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url('./Roboto-Bold.woff2') format('woff2'),
    url('./Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
