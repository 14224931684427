@font-face {
    font-family: Mark;
    src: url('./MarkWeb-latin-regular.woff2') format('woff2'),
    url('./MarkWeb-latin-regular.woff') format('woff');
    font-weight:400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Mark;
    src: url('./MarkWeb-latin-medium.woff2') format('woff2'),
    url('./MarkWeb-latin-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Mark;
    src: url('./MarkWeb-latin-bold.woff2') format('woff2'),
    url('./MarkWeb-latin-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
