@font-face {
    font-family: Averta;
    src: url('./Averta-Regular.woff2') format('woff2'),
    url('./Averta-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Averta;
    src: url('./Averta-Medium.woff2') format('woff2'),
    url('./Averta-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Averta;
    src: url('./Averta-Medium.woff2') format('woff2'),
    url('./Averta-Medium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Averta;
    src: url('./Averta-Bold.woff2') format('woff2'),
    url('./Averta-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
