/* biryani-latin-200-normal*/
@font-face {
  font-family: "Biryani";
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("./files/BiryaniLatin-UltraLight.woff2") format("woff2"),
    url("./files/BiryaniLatin-UltraLight.woff") format("woff");
}
/* biryani-latin-300-normal*/
@font-face {
  font-family: "Biryani";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("./files/BiryaniLatin-Light.woff2") format("woff2"),
    url("./files/BiryaniLatin-Light.woff") format("woff");
}
/* biryani-latin-400-normal*/
@font-face {
  font-family: "Biryani";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("./files/BiryaniLatin-Regular.woff2") format("woff2"),
    url("./files/BiryaniLatin-Regular.woff") format("woff");
}
/* biryani-latin-600-normal*/
@font-face {
  font-family: "Biryani";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("./files/BiryaniLatin-SemiBold.woff2") format("woff2"),
    url("./files/BiryaniLatin-SemiBold.woff") format("woff");
}
/* biryani-latin-700-normal*/
@font-face {
  font-family: "Biryani";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("./files/BiryaniLatin-Bold.woff2") format("woff2"),
    url("./files/BiryaniLatin-Bold.woff") format("woff");
}
/* biryani-latin-800-normal*/
@font-face {
  font-family: "Biryani";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("./files/BiryaniLatin-ExtraBold.woff2") format("woff2"),
    url("./files/BiryaniLatin-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("./files/Inter-Thin.woff2?v=3.19") format("woff2"),
  url("./files/Inter-Thin.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("./files/Inter-ThinItalic.woff2?v=3.19") format("woff2"),
  url("./files/Inter-ThinItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("./files/Inter-ExtraLight.woff2?v=3.19") format("woff2"),
  url("./files/Inter-ExtraLight.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("./files/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"),
  url("./files/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("./files/Inter-Light.woff2?v=3.19") format("woff2"),
  url("./files/Inter-Light.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("./files/Inter-LightItalic.woff2?v=3.19") format("woff2"),
  url("./files/Inter-LightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("./files/Inter-Regular.woff2?v=3.19") format("woff2"),
  url("./files/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("./files/Inter-Italic.woff2?v=3.19") format("woff2"),
  url("./files/Inter-Italic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("./files/Inter-Medium.woff2?v=3.19") format("woff2"),
  url("./files/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("./files/Inter-MediumItalic.woff2?v=3.19") format("woff2"),
  url("./files/Inter-MediumItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("./files/Inter-SemiBold.woff2?v=3.19") format("woff2"),
  url("./files/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("./files/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"),
  url("./files/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("./files/Inter-Bold.woff2?v=3.19") format("woff2"),
  url("./files/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("./files/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
  url("./files/Inter-BoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("./files/Inter-ExtraBold.woff2?v=3.19") format("woff2"),
  url("./files/Inter-ExtraBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("./files/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"),
  url("./files/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("./files/Inter-Black.woff2?v=3.19") format("woff2"),
  url("./files/Inter-Black.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("./files/Inter-BlackItalic.woff2?v=3.19") format("woff2"),
  url("./files/Inter-BlackItalic.woff?v=3.19") format("woff");
}
