@font-face {
    font-family: Montserrat;
    src: url('./Montserrat-Regular.woff2') format('woff2'),
    url('./Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat-Medium.woff2') format('woff2'),
    url('./Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat-SemiBold.woff2') format('woff2'),
    url('./Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat-Bold.woff2') format('woff2'),
    url('./Montserrat-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
