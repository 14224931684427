@font-face {
    font-family: Archivo;
    src: url('./Archivo-Regular.woff2') format('woff2'),
    url('./Archivo-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Archivo;
    src: url('./Archivo-Medium.woff2') format('woff2'),
    url('./Archivo-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Archivo;
    src: url('./Archivo-Bold.woff2') format('woff2'),
    url('./Archivo-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
